import React from 'react'

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import './styles.css'

const HeaderAdministrator = ({ handleLogOut, nwLogo, logoutText }) => {
  return (
    <header id='header-admin'>
      <div className='header-admin-logo'>
        <img src={nwLogo} alt='new way logo' />
        <h2>Admin</h2>
      </div>
      <div className='header-admin-sign-out'>
        <button type='button' onClick={() => handleLogOut()}>
          <FontAwesomeIcon id='icon' icon={faSignOutAlt} size='lg' />
          {logoutText}
        </button>
      </div>
    </header>
  )
}

export default HeaderAdministrator

HeaderAdministrator.propTypes = {
  handleLogOut: PropTypes.func.isRequired,
  nwLogo: PropTypes.string.isRequired,
  logoutText: PropTypes.string.isRequired
}
